.main {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 140px);
}
.main__content {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 100px;
}
.main__content__title {
  font-size: 50px;
  font-weight: 700;
}

.main__content__title__highlight {
  color: #5865f2;
}

.main__content__connect {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.main__content__connect__item {
  margin-top: 30px;
}

#confetti {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: block;
}
