.metamask-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  color: black;
  background-color: white;
}

.metamask-tooltip {
  font-size: 12px;
}

.metamask-button:hover {
  background-color: white;
}
.metamask-button-img {
  width: 30px;
  height: 30px;
  margin-right: 3px;
}
.metamask-button-text {
  padding-left: 5px;
}

.connectedDot {
  height: 7px;
  width: 7px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
