.footer {
  width: 100%;
  height: 70px;
  background-color: black;
}

.footer-content {
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.footer-social {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.footer-social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 40px;
  font-weight: 600;
  text-decoration: none;
}
.footer-social-link:hover {
  cursor: pointer;
  color: #5865F2;
}

.footer-social-link-img {
  width: 18px;
  margin: 0 0 2px 3px;
}

