.dashboard {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.dashboard__header {
  font-size: 30px;
  padding-bottom: 10px;
  font-weight: bold;
}
.dashboard__body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
}

.dashboard__body__item {
  padding-top: 10px;
  font-size: 20px;
  line-height: 25px;
}

.success_color {
  color: green;
}

.fail_color {
  color: red;
  margin-left: 4px;
}
