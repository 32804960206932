.components {
  position: relative;
  width: 100%;
  height: 100%;
}

#confetti {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}
