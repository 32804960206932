.connect_discord {
  display: flex;
  align-items: center;
}
.connect_discord__button {
  min-width: 200px;
  height: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  background-color: #5865F2;
  border-radius: 5px;
  font-size: 20px;
}
.connect_discord__button__disabled {
  pointer-events: none;
  opacity: 0.7;
}
.connect_discord__button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.connect_discord__button__logo {
  height: 15px;
}

.connect_discord__button__text {
  color: white;
  margin-left: 10px;
}
.connect_discord__success {
  margin-left: 3px;
  font-size: 20px;
}
