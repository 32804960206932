.header {
  height: 70px;
  background-color: black;
  padding: 0 20px;
}

.header-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.header-logo-image {
  width: 35px;
  margin-right: 5px;
  border-radius: 3px;
}

.header-logo-text {
  color: white;
  font-size: 26px;
  margin-top: 3px;
}

.header-wallets {
  display: flex;
}

.header-wallets-connect-wallet {
  font-family: 'Neucha', cursive !important;
  margin-right: 10px;
  background-color: #5865F2 !important;
}
.header-wallets-disconnect-wallet  {
  font-family: 'Neucha', cursive !important;
}


